import {
  searchProperty,
  intgAuditARI,
  intgAuditARIHBRes,
  getProperty,
  getPropertyImages,
  getAllClients,
  addAClient,
  getSourceCommission,
  updateSourceCommission,
  addSourceCommission,
  getAllSuppliers,
  blacklistProperty,
  getBlacklistedProperties,
  reListProperty,
  getDirectCommissions,
  addOrUpdateRateplans,
  getClientById,
  getClient,
  updateClientById,
  updateClient,
  getTaxes,
  updatePropertyAmenities,
  deleteAmenity,
  getReportDashboard,
  getResTotals,
  searchReservationReportOld,
  getAriDeliveryInboundSummary,
  getAriDeliveryOutboundSummary,
  getNonActionedMetadataNotificationCounts,
  updateClientDocuments
} from "../api";
import { toast } from "react-toastify";
import { processReservationReportForRendering } from "../components/ReservationReport/utils";
import {
  isASuperUserLoggedIn,
  isASupplierUserLoggedIn,
  isClientWithInvoiceAccess
} from "../utils/secure-xs/secureSS";
import { getInvoiceActionItems } from "../api/invoice";
import { getFormattedUTCDateTime } from "../constants";

export const SET_PROPERTY_NAME_TO_SEARCH = "SET_PROPERTY_NAME_TO_SEARCH";
export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";
export const UPDATE_SELECTED_PROPERTY = "UPDATE_SELECTED_PROPERTY";
export const UPDATE_SEARCHED_PROPERTIES = "UPDATE_SEARCHED_PROPERTIES";
export const SET_SEARCH_PROPERTY_LOADING = "SET_SEARCH_PROPERTY_LOADING";
export const SET_RESERVATION_REPORT_LOADING = "SET_RESERVATION_REPORT_LOADING";
export const SET_SHOW_RESERVATION_REPORT = "SET_SHOW_RESERVATION_REPORT";
export const SET_RESERVATION_REPORT = "SET_RESERVATION_REPORT";
export const SET_INTG_AUDIT_ARI_CONTENT = "SET_INTG_AUDIT_ARI_CONTENT";
export const SET_HB_RES_INTG_AUDIT_ARI_CONTENT = "SET_HB_RES_INTG_AUDIT_ARI_CONTENT";
export const SET_INTG_AUDIT_ARI_LOADING = "SET_INTG_AUDIT_ARI_LOADING";
export const TOGGLE_INTG_AUDIT_ARI_POPUP = "TOGGLE_INTG_AUDIT_ARI_POPUP";
export const TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP = "TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP";
export const SET_INTG_AUDIT_ARI_REQ_PARAMS = "SET_INTG_AUDIT_ARI_REQ_PARAMS";
export const SET_SELECTED_PROPERTY = "SET_SELECTED_PROPERTY";
export const SET_PROPERTY_AMENITIES = "SET_PROPERTY_AMENITIES";
export const SET_PROPERTY_IMAGES = "SET_PROPERTY_IMAGES";
export const UPDATE_REPORT_COLUMNS = "UPDATE_REPORT_COLUMNS";
export const SET_PROPERTY_TAXES = "SET_PROPERTY_TAXES";
export const SET_ALL_CLIENTS = "SET_ALL_CLIENTS";
export const SET_A_CLIENT = "SET_A_CLIENT";
export const SET_ALL_SUPPLIERS = "SET_ALL_SUPPLIERS";
export const SET_SOURCE_COMMISSION = "SET_SOURCE_COMMISSION";
export const SET_RESERVATION_REPORT_REQ_PARAMS = "SET_RESERVATION_REPORT_REQ_PARAMS";
export const TOGGLE_BLACKLISTED_PROP_LIST = "TOGGLE_BLACKLISTED_PROP_LIST";
export const SET_BLACKLISTED_PROPS = "SET_BLACKLISTED_PROPS";
export const SET_BLACKLISTED_PROPS_LOADING = "SET_BLACKLISTED_PROPS_LOADING";
export const SET_DIRECT_COMMISSIONS = "SET_DIRECT_COMMISSIONS";
export const UPDATED_COLUMNS_HIDDEN = "UPDATED_COLUMNS_HIDDEN";
export const COLUMNS_FILTER_SELECTED = "COLUMNS_FILTER_SELECTED";
export const SET_REPORT_DASHBOARD_LOADING = "SET_REPORT_DASHBOARD_LOADING";
export const REPORT_DASHBOARD = "REPORT_DASHBOARD";
export const SET_ARI_REPORT_DASHBOARD_LOADING = "SET_ARI_REPORT_DASHBOARD_LOADING";
export const ARI_REPORT_DASHBOARD = "ARI_REPORT_DASHBOARD";
export const SET_RES_TOTALS_LOADING = "SET_RES_TOTALS_LOADING";
export const RES_TOTAL = "RES_TOTAL";
export const SET_ACTIONABLE_INVOICES_COUNT = "SET_ACTIONABLE_INVOICES_COUNT";
export const SET_METADATA_NOTIFICATIONS_COUNT = "SET_METADATA_NOTIFICATIONS_COUNT";

//action creators
export const updateReportColumns = reservationReportColumns => ({
  type: UPDATE_REPORT_COLUMNS,
  reservationReportColumns
});
export const setSearchPropertyBy = payload => ({
  type: SET_PROPERTY_NAME_TO_SEARCH,
  payload
});
export const updateSelectedProperty = property => {
  localStorage.setItem("selected_property", JSON.stringify(property));

  return {
    type: UPDATE_SELECTED_PROPERTY,
    property
  };
};
export const setSearchPropertyLoading = isSearchPropertyLoading => ({
  type: SET_SEARCH_PROPERTY_LOADING,
  isSearchPropertyLoading
});
export const setSearchReservationReportLoading = isReservationReportLoading => ({
  type: SET_RESERVATION_REPORT_LOADING,
  isReservationReportLoading
});
export const setReservationReportRequestParams = reservationReportReqParams => ({
  type: SET_RESERVATION_REPORT_REQ_PARAMS,
  reservationReportReqParams
});
export const setClientDetails = clientDetails => ({
  type: SET_CLIENT_DETAILS,
  clientDetails
});

export const updateSearchedProperties = properties => ({
  type: UPDATE_SEARCHED_PROPERTIES,
  properties
});
export const setSelectedProperty = selectedProperty => ({
  type: SET_SELECTED_PROPERTY,
  selectedProperty
});
export const setPropertyTaxes = propertyTaxes => ({
  type: SET_PROPERTY_TAXES,
  propertyTaxes
});
export const setPropertyImages = propertyImages => ({
  type: SET_PROPERTY_IMAGES,
  propertyImages
});
export const setPropertyAmenities = propertyAmenities => ({
  type: SET_PROPERTY_AMENITIES,
  propertyAmenities
});
export const setReservationReport = reservationReports => ({
  type: SET_RESERVATION_REPORT,
  reservationReports
});
export const setIntgAuditARIContent = intgAuditARIContent => ({
  type: SET_INTG_AUDIT_ARI_CONTENT,
  intgAuditARIContent
});
export const setHBResIntgAuditARIContent = hbResintgAuditARIContent => ({
  type: SET_HB_RES_INTG_AUDIT_ARI_CONTENT,
  hbResintgAuditARIContent
});
export const toggleIntgAuditARIPopup = showIntgAuditARIPopup => ({
  type: TOGGLE_INTG_AUDIT_ARI_POPUP,
  showIntgAuditARIPopup
});
export const toggleIntgAuditARIHBResPopup = showIntgAuditARIHBResPopup => ({
  type: TOGGLE_INTG_AUDIT_ARI_HB_RES_POPUP,
  showIntgAuditARIHBResPopup
});
export const setIntgAuditARILoading = isIntgAuditARILoading => ({
  type: SET_INTG_AUDIT_ARI_LOADING,
  isIntgAuditARILoading
});

export const setIntgAuditARIReqParams = intgAuditARIReqParams => ({
  type: SET_INTG_AUDIT_ARI_REQ_PARAMS,
  intgAuditARIReqParams
});
export const setAllClients = allClients => ({
  type: SET_ALL_CLIENTS,
  allClients
});
export const setAClient = clientToEdit => ({
  type: SET_A_CLIENT,
  clientToEdit
});
export const setAllSuppliers = allSuppliers => ({
  type: SET_ALL_SUPPLIERS,
  allSuppliers
});

export const setSourceCommission = sourceCommission => ({
  type: SET_SOURCE_COMMISSION,
  sourceCommission
});
export const toggleBlacklistedPropertyList = showBlacklistedProperties => ({
  type: TOGGLE_BLACKLISTED_PROP_LIST,
  showBlacklistedProperties
});
export const setBlackListedProperties = blacklistedProperties => ({
  type: SET_BLACKLISTED_PROPS,
  blacklistedProperties
});
export const setBlackListedPropsLoading = blacklistedPropertiesLoading => ({
  type: SET_BLACKLISTED_PROPS_LOADING,
  blacklistedPropertiesLoading
});
export const setDirectCommissions = directCommissions => ({
  type: SET_DIRECT_COMMISSIONS,
  directCommissions
});
export const updateColumnsHidden = columnsHidden => ({
  type: UPDATED_COLUMNS_HIDDEN,
  columnsHidden
});
export const updateColumnsFilterSelected = columnsFilterSelected => ({
  type: COLUMNS_FILTER_SELECTED,
  columnsFilterSelected
});
export const setIsReportDashboardLoading = isReportDashboardLoading => {
  return { type: SET_REPORT_DASHBOARD_LOADING, isReportDashboardLoading };
};
export const setReportDashboard = reportDashboard => {
  return { type: REPORT_DASHBOARD, reportDashboard };
};
export const setIsAriReportDashboardLoading = isAriReportDashboardLoading => {
  return { type: SET_ARI_REPORT_DASHBOARD_LOADING, isAriReportDashboardLoading };
};
export const setAriReportDashboard = ariReportDashboard => {
  return { type: ARI_REPORT_DASHBOARD, ariReportDashboard };
};
export const setIsResTotalsLoading = isResTotalsLoading => {
  return { type: SET_RES_TOTALS_LOADING, isResTotalsLoading };
};
export const setResTotals = resTotals => {
  return { type: RES_TOTAL, resTotals };
};
export const setActionableInvoicesCount = actionableInvoicesCount => {
  return { type: SET_ACTIONABLE_INVOICES_COUNT, actionableInvoicesCount };
};
export const setMetadataNotificationsCount = metadataNotificationsCount => {
  return { type: SET_METADATA_NOTIFICATIONS_COUNT, metadataNotificationsCount };
};
// async actions
export const asyncGetProperty = (propId, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  getProperty(propId).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(setSelectedProperty(success.body));
      }
      asyncGetPropertyAmenities(propId, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetInvoiceActionItems = dispatch => {
  if (isASupplierUserLoggedIn() || !isClientWithInvoiceAccess()) return;
  dispatch(setSearchPropertyLoading(true));

  const isClient = !isASuperUserLoggedIn();
  getInvoiceActionItems(isClient)
    .then(resp => {
      dispatch(setActionableInvoicesCount(resp.body));
    })
    .catch(err => console.log(err));
};
export const asyncGetMetadataNotificationsCount = dispatch => {
  dispatch(setSearchPropertyLoading(true));

  getNonActionedMetadataNotificationCounts()
    .then(resp => {
      dispatch(setMetadataNotificationsCount(resp.body));
    })
    .catch(err => console.log(err));
};

export const asyncGetPropertyTaxes = (propName, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  getTaxes(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(setPropertyTaxes(success.body.propertyList));
      }
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};

export const asyncUpdatePropertyAmenities = (propName, callback, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  updatePropertyAmenities(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      callback();
      asyncGetPropertyAmenities({ propertyName: propName.propertyName }, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};
export const asyncDeletePropertyAmenities = (propName, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  deleteAmenity(propName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      asyncGetPropertyAmenities({ propertyName: propName.propertyName }, dispatch);
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetPropertyAmenities = (propId, dispatch) => {
  dispatch(setPropertyAmenities([]));

  dispatch(setPropertyImages([]));
};
export const asyncGetPropertyImages = (propId, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  getPropertyImages(propId).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(setPropertyImages(success.body.propertyImagesList));
      }
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetReportDashboard = (params, dispatch) => {
  dispatch(setIsReportDashboardLoading(true));
  getReportDashboard().then(
    success => {
      dispatch(setIsReportDashboardLoading(false));
      if (success.body) {
        dispatch(setReportDashboard(success.body));
      }
    },
    failure => {
      dispatch(setIsReportDashboardLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetAriReportDashboard = (params, dispatch) => {
  dispatch(setIsAriReportDashboardLoading(true));
  const reqBody = {
    ariTypeIds: [],
    clientIds: [],
    errorCodes: [],
    gteTimestamp: getFormattedUTCDateTime(
      new Date(new Date() - 60 * 60 * 1000),
      "yyyy-mm-dd'T'HH:MM:ss"
    ),
    lteTimestamp: getFormattedUTCDateTime(new Date(), "yyyy-mm-dd'T'HH:MM:ss"),
    operationIds: [],
    propertyCodes: [],
    rateCodes: [],
    roomCodes: [],
    statusIds: [],
    supplierIds: [],
    size: 30
  };
  Promise.all([getAriDeliveryInboundSummary(reqBody), getAriDeliveryOutboundSummary(reqBody)]).then(
    success => {
      dispatch(setIsAriReportDashboardLoading(false));
      const ariInbound = [];
      const aggInbound = { total: 0, passed: 0, failed: 0 };
      const ariOutbound = [];

      if (success[0].body.success) {
        const inbound = success[0].body.data.properties;
        for (const key in inbound) {
          const temp = key.split(", ");
          const pId = temp.shift();
          const pname = temp.join(",");
          let failed = inbound[key]["RESP_ERROR"] ? inbound[key]["RESP_ERROR"] : 0;
          failed += inbound[key]["FAILURE"] ? inbound[key]["FAILURE"] : 0;
          const passed = inbound[key]["SUCCESS"] ? inbound[key]["SUCCESS"] : 0;
          const tot = failed + passed;
          ariInbound.push([pname, tot, failed, passed, pId]);
        }

        const agg = success[0].body.data.aggInbound;
        for (const key in agg) {
          aggInbound.total +=
            (agg[key].SUCCESS ? agg[key].SUCCESS : 0) + (agg[key].FAILURE ? agg[key].FAILURE : 0);
          aggInbound.passed += agg[key].SUCCESS ? agg[key].SUCCESS : 0;
          aggInbound.failed += agg[key].FAILURE ? agg[key].FAILURE : 0;
        }
      } else {
        console.log(success[0].body.errorDescription);
      }
      if (success[1].body.success) {
        const outbound = success[1].body.data.clients;
        for (const key in outbound) {
          const temp = key.split(", ");
          const cId = temp.shift();
          const cname = temp.join(",");
          let failed = outbound[key]["RESP_ERROR"] ? outbound[key]["RESP_ERROR"] : 0;
          failed += outbound[key]["FAILURE"] ? outbound[key]["FAILURE"] : 0;
          const passed = outbound[key]["SUCCESS"] ? outbound[key]["SUCCESS"] : 0;
          const tot = failed + passed;
          ariOutbound.push([cname, tot, failed, passed, parseInt(cId)]);
        }
      } else {
        console.log(success[1].body.errorDescription);
      }

      if (success[0].body.success || success[1].body.success) {
        const ariSummary = {
          outgoingAriResponse: ariOutbound,
          incomingAriResponse: ariInbound,
          aggInbound: aggInbound
        };
        dispatch(setAriReportDashboard(ariSummary));
      }
    },
    failure => {
      dispatch(setIsAriReportDashboardLoading(false));
      console.log(failure);
    }
  );
  /*getAriReportDashboard().then(
    success => {
      dispatch(setIsAriReportDashboardLoading(false));
      if (success.body) {
        dispatch(setAriReportDashboard(success.body));
      }
    },
    failure => {
      dispatch(setIsAriReportDashboardLoading(false));
      console.log(failure);
    }
  );*/
};

export const asyncGetResTotals = (params, dispatch) => {
  dispatch(setIsResTotalsLoading(true));
  getResTotals(params).then(
    success => {
      dispatch(setIsResTotalsLoading(false));
      if (success.body) {
        dispatch(setResTotals(success.body));
      }
    },
    failure => {
      dispatch(setIsResTotalsLoading(false));
      console.log(failure);
    }
  );
};

export const asyncSearchProperty = (propertyName, dispatch) => {
  dispatch(setSearchPropertyLoading(true));
  return searchProperty(propertyName).then(
    success => {
      dispatch(setSearchPropertyLoading(false));
      if (success.body) {
        dispatch(updateSearchedProperties(success.body));
      }
    },
    failure => {
      dispatch(setSearchPropertyLoading(false));
      dispatch(updateSearchedProperties([]));
      toast.error("Error while fetching the property");
    }
  );
};
export const asyncSearchReservationReport = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  dispatch(setReservationReport([]));

  return searchReservationReportOld(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        const reports = success.body;

        dispatch(
          setReservationReport(processReservationReportForRendering(reports, params.isMultiService))
        );
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
      if (failure.name === "Error") {
        toast.error("Error while fetching the reservation");
      }
    }
  );
};

export const asyncIntgAuditARI = (params, dispatch) => {
  dispatch(setIntgAuditARIReqParams(params));
  dispatch(setIntgAuditARILoading(true));
  if (params.clientCode === "Hotelbeds" && params.operation === "RESERVATIONNOTIFRQ_OUT") {
    return intgAuditARIHBRes(
      params.htConfirmationCode,
      new Date(params.saleDate).getTime() + 19800000
    ).then(
      success => {
        dispatch(setIntgAuditARILoading(false));
        if (success.message) {
          return;
        }
        dispatch(toggleIntgAuditARIHBResPopup(true));
        if (success.body) {
          dispatch(setHBResIntgAuditARIContent(success.body.reservationXmlList));
        }
      },
      failure => {
        dispatch(setIntgAuditARILoading(false));
        dispatch(toggleIntgAuditARIHBResPopup(false));
        console.log(failure);
      }
    );
  } else {
    return intgAuditARI(params).then(
      success => {
        dispatch(setIntgAuditARILoading(false));
        if (success.message) {
          return;
        }
        dispatch(toggleIntgAuditARIPopup(true));
        if (success.body) {
          dispatch(setIntgAuditARIContent(success.body));
        }
      },
      failure => {
        dispatch(setIntgAuditARILoading(false));
        dispatch(toggleIntgAuditARIPopup(false));
        console.log(failure);
      }
    );
  }
};

export const asyncGetAllClients = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return getAllClients(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setAllClients(success.body));
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetAClient = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  const api = isASuperUserLoggedIn() ? getClientById : getClient;
  return api(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setClientDetails({ ...success.body, fromServer: true }));
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncUpdateClient = (params, history, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  const api = isASuperUserLoggedIn() ? updateClientById : updateClient;
  return api(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        toast.success("Successfully updated client");
        if (isASuperUserLoggedIn()) {
          if (params.clientFilesUpdated) {
            dispatch(setSearchReservationReportLoading(true));
            updateClientDocuments(params.clientCode, params.clientDocuments).then(
              res => {
                dispatch(setSearchReservationReportLoading(false));
                if (res.body) {
                  history.push("/home/clients/list");
                } else {
                  toast.error("Error updating the client files");
                }
              },
              error => {
                toast.error("Failed to update a Client");
                dispatch(setSearchReservationReportLoading(false));
                console.log(error);
              }
            );
          } else {
            history.push("/home/clients/list");
          }
        }
      } else {
        toast.error("Error updating the client");
      }
    },
    failure => {
      toast.error("Failed to update a Client");
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncAddClient = (params, history, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return addAClient(params).then(
    success => {
      if (success.body.success) {
        toast.success("Successfully added a Client");
        if (params.clientFilesUpdated) {
          dispatch(setSearchReservationReportLoading(true));
          updateClientDocuments(params.clientCode, params.clientDocuments).then(
            res => {
              dispatch(setSearchReservationReportLoading(false));
              if (res.body) {
                history.push("/home/clients/list");
              } else {
                toast.error("Error updating the client files");
              }
            },
            error => {
              toast.error("Failed to update a Client");
              dispatch(setSearchReservationReportLoading(false));
              console.log(error);
            }
          );
        } else {
          history.push("/home/clients/list");
        }
      } else {
        toast.error("Error creating the client.\n" + success.errorMessage);
      }
      dispatch(setSearchReservationReportLoading(false));
    },
    failure => {
      toast.error("Error creating the client");
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncGetSourceCommission = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return getSourceCommission(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setSourceCommission(success.body));
      }
    },
    failure => {
      toast.error("Failed to add a Client");
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncAddSourceCommission = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return addSourceCommission(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      asyncGetSourceCommission(params.username, dispatch);
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncUpdateSourceCommission = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return updateSourceCommission(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      asyncGetSourceCommission(params.username, dispatch);
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetAllSuppliers = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return getAllSuppliers(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        dispatch(setSourceCommission(success.body));
        dispatch(setAllSuppliers(success.body));
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncBlackListProperty = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  return blacklistProperty(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        toast.success("Successfully Blacklisted a property");
        asyncGetBlacklistedProperties(params.user, dispatch);
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};
export const asyncReListProperty = (params, dispatch) => {
  dispatch(setSearchReservationReportLoading(true));
  reListProperty(params).then(
    success => {
      dispatch(setSearchReservationReportLoading(false));
      if (success.body) {
        toast.success("Successfully Whitelisted a property");
        asyncGetBlacklistedProperties(params.user, dispatch);
      }
    },
    failure => {
      dispatch(setSearchReservationReportLoading(false));
      console.log(failure);
    }
  );
};

export const asyncGetBlacklistedProperties = (propId, dispatch) => {
  dispatch(setBlackListedPropsLoading(true));
  getBlacklistedProperties(propId).then(
    success => {
      dispatch(setBlackListedPropsLoading(false));
      if (success.body) {
        dispatch(setBlackListedProperties(success.body));
      }
    },
    failure => {
      dispatch(setBlackListedPropsLoading(false));
      console.log(failure);
    }
  );
};
export const asyncGetDirectCommissions = (userName, dispatch) => {
  dispatch(setBlackListedPropsLoading(true));
  getDirectCommissions(userName).then(
    success => {
      dispatch(setBlackListedPropsLoading(false));
      if (success.body) {
        dispatch(setDirectCommissions(success.body.propertyRateplans));
      }
    },
    failure => {
      dispatch(setBlackListedPropsLoading(false));
      console.log(failure);
    }
  );
};
export const asyncAddOrUpdateRateplans = ({ userName, propertyId, rateplan }, dispatch) => {
  dispatch(setBlackListedPropsLoading(true));
  addOrUpdateRateplans(userName, propertyId, rateplan).then(
    success => {
      dispatch(setBlackListedPropsLoading(false));
      if (success.body) {
        asyncGetDirectCommissions(userName, dispatch);
      }
    },
    failure => {
      dispatch(setBlackListedPropsLoading(false));
      console.log(failure);
    }
  );
};
